import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteParamService {
  private idSubject = new BehaviorSubject<string | null>(null);
  id$ = this.idSubject.asObservable();


  setId(id: string | null) {
    this.idSubject.next(id);
  }
}
